module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#0F7094"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SMB Group Iraq","short_name":"SMB Group","start_url":"/","background_color":"#fdfdfd","theme_color":"#0F7094","display":"minimal-ui","icon":"src/images/smb-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8e2074704608213bcb6ffd147bcf9230"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
