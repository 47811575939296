// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-construction-jsx": () => import("./../../../src/pages/services/construction.jsx" /* webpackChunkName: "component---src-pages-services-construction-jsx" */),
  "component---src-pages-services-equipment-jsx": () => import("./../../../src/pages/services/equipment.jsx" /* webpackChunkName: "component---src-pages-services-equipment-jsx" */),
  "component---src-pages-services-hse-jsx": () => import("./../../../src/pages/services/hse.jsx" /* webpackChunkName: "component---src-pages-services-hse-jsx" */),
  "component---src-pages-services-lifesupport-jsx": () => import("./../../../src/pages/services/lifesupport.jsx" /* webpackChunkName: "component---src-pages-services-lifesupport-jsx" */),
  "component---src-pages-services-logistics-jsx": () => import("./../../../src/pages/services/logistics.jsx" /* webpackChunkName: "component---src-pages-services-logistics-jsx" */),
  "component---src-pages-services-oilgas-jsx": () => import("./../../../src/pages/services/oilgas.jsx" /* webpackChunkName: "component---src-pages-services-oilgas-jsx" */)
}

